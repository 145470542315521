<template>
  <div>
    <div class="active-filters row" :key="counter">
      <div
        v-for="filter in filters"
        :key="filter.id"
        :class="
          filter.type == 'price' ||
          filter.type == 'year' ||
          filter.type == 'kms' ||
          filter.type == 'fee'
            ? 'single-active-filter grid-two-col'
            : 'single-active-filter'
        "
      >
        <div
          class="filter-item center"
          v-if="
            filter.type == 'price' ||
            filter.type == 'year' ||
            filter.type == 'kms' ||
            filter.type == 'fee'
          "
        >
          <div
            v-if="filter.type == 'price' || filter.type == 'fee'"
            class="button-label"
          >
            <span v-if="filter.min">{{
              formatPriceNumber(filter.min, "0", "€")
            }}</span>
            <span style="margin: 5px">-</span>
            <span v-if="filter.max">{{
              formatPriceNumber(filter.max, "0", "€")
            }}</span>
          </div>
          <div v-else class="button-label">
            <span v-if="filter.min">{{ filter.min }}</span>
            <span style="margin-left: 5px; margin-right: 5px">-</span>
            <span v-if="filter.max">{{ filter.max }}</span>
          </div>
          <span class="remove-icon" @click="onClick(filter)"><xIcon /></span>
        </div>
        <div class="filter-item" v-else>
          <div class="button-label">
            <span>
              {{ filter.value.replace("%20", " ") }}
              <span v-if="filter.count > 1">(+{{ filter.count }})</span>
            </span>
          </div>
          <span class="remove-icon" @click="onClick(filter)"><xIcon /></span>
        </div>

        <!-- <div class="filter-item" v-else-if="!filter.hasOwnProperty('type')">
          <div class="button-label">
            <span>
              {{ filter[0].value }}
              <span v-if="filter.length > 1">(+{{ filter.length }})</span>
            </span>
          </div>
          <span class="remove-icon" @click="onClick(filter)"><xIcon /></span>
        </div> -->
      </div>
    </div>
    <div
      v-if="filters.length > 1"
      @click="removeAllFilter"
      class="clear-filters"
    >
      <p>Limpar Filtros</p>
    </div>
  </div>
</template>

<script>
import xIcon from "@/assets/x.vue";
import { formatPriceNumber } from "@/functions/general.js";
export default {
  name: "ActiveFilter",
  data() {
    return {
      filters: [...this.$store.state.activeFilters],
      counter: 0,
      arrTest: [],
    };
  },
  components: {
    xIcon,
  },
  watch: {
    "$store.state.changeCounter": function () {
      this.filters = [...this.$store.state.activeFilters];
      if (this.filters.length > 0) {
        /* let arrTest = [];
  
          this.filters.forEach((el) => {
            if (el.type == "model") {
              arrTest.push(el);
              const index = this.filters.indexOf(el);
              console.log(index);
              if (index > -1) {
                this.filters.splice(index, 1);
              }
            }
          });
          const toSave = {
            count: arrTest.length,
            id: arrTest[0].id,
            type: "model",
            value: arrTest[0].value,
          };
          this.filters.push(toSave); */
        this.counter++;
        this.$forceUpdate();
      }
    },
  },
  methods: {
    formatPriceNumber,
    // FUNCTION CALLED WHEN USER CLICKS ON BUTTON TO DELETE FILTER
    onClick(prop) {
      this.$emit("onRemoveFilter", prop);
    },
    removeAllFilter() {
      this.$emit("removeAllFilter");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/FilterUsedCars";
</style>
