<template>
  <div class="slider-wrapper">
    <!-- <div class="double-input">
      <input
        type="text"
        :id="labelMin + '_inputMin'"
        class="field"
        :value="min"
      />
      <input
        type="text"
        :id="labelMax + '_inputMax'"
        class="field"
        :value="max"
      />
    </div> -->
    <div class="filter-range row">
      <InputNumber
        :value="Math.round(min)"
        :id="labelMin + '_inputMin'"
        :type="labelMin"
        propType="price"
        @inputChange="updateRangeInput('min')"
      />
      <span>-</span>
      <InputNumber
        :value="Math.round(max)"
        :id="labelMax + '_inputMax'"
        propType="price"
        :type="labelMax"
        @inputChange="updateRangeInput('max')"
      />
    </div>
    <div class="double-slider" v-if="min != max">
      <div
        class="double-slider__track"
        :id="labelMin + 'double-slider__track'"
      ></div>
      <input
        type="range"
        :min="min"
        :max="max"
        :value="minValue"
        :id="labelMin + '_sliderMin'"
        class="double-slider__thumb"
        @input="updateRangeSlide('min')"
        @mouseup="sendValue"
        @touchend="sendValue"
      />
      <input
        :id="labelMax + '_sliderMax'"
        class="double-slider__thumb"
        @input="updateRangeSlide('max')"
        type="range"
        :min="min"
        :max="max"
        :value="maxValue"
        @mouseup="sendValue"
        @touchend="sendValue"
      />
    </div>
  </div>

  <!-- <div class="input-range">
    <input
      type="range"
      :min="min"
      :max="max"
      :value="minValue"
      @change="onChange($event, labelMin)"
      @input="onInput($event, labelMin)"
    />
    <input
      type="range"
      :min="min"
      :max="max"
      :value="maxValue"
      @change="onChange($event, labelMax)"
      @input="onInput($event, labelMax)"
    />
  </div> -->
</template>

<script>
import InputNumber from "@/components/Inputs/InputNumber.vue";

export default {
  name: "InputRange",
  components: {
    InputNumber,
  },
  props: {
    labelMin: {
      type: String,
      default: "",
    },
    min: {
      type: [Number, String],
      default: 0,
    },
    max: {
      type: [Number, String],
      default: 0,
    },
    labelMax: {
      type: String,
      default: "",
    },
    minValue: {
      type: [Number, String],
      default: 0,
    },
    maxValue: {
      type: [Number, String],
      default: 0,
    },
    step: {
      type: Array,
    },
  },
  data() {
    return {
      minGap: 1,
      type: "",
    };
  },
  computed: {},
  created() {
    setTimeout(() => {
      this.updateRangeSlide("min");
      this.updateRangeSlide("max");
      if (this.labelMax === "priceSup") {
        this.type = "price";
      } else if (this.labelMax === "monthlyPaymentSup") {
        this.type = "fee";
      } else if (this.labelMax === "kmSup") {
        this.type = "kms";
      } else {
        this.type = "year";
      }
    }, 1000);
  },
  methods: {
    // FUNCTION CALLED WHEN VALUE CHANGES ON THE RANGE AFTER MOUSE UP
    /* onChange(event, range) {
      this.$emit(
        "onUpdateValue",
        { value: event.target.value, range: range },
        range
      );
    },
    // FUNCTION CALLED WHEN VALUE CHANGES ON THE RAGE ON THE FLY
    onInput(event, range) {
      this.$emit("onUpdateLabel", { value: event.target.value, range: range });
    }, */
    fillColor() {
      const sliderMin = document.querySelector(`#${this.labelMin}_sliderMin`);
      const sliderMax = document.querySelector(`#${this.labelMax}_sliderMax`);
      const sliderMinValue = sliderMin.min;
      const sliderTrack = document.querySelector(
        `#${this.labelMin}double-slider__track`
      );
      const interval = sliderMin.max - sliderMin.min;
      const difMin = sliderMin.value - sliderMinValue;
      const min = (difMin * 100) / interval;
      const difMax = sliderMax.value - sliderMinValue;
      const max = (difMax * 100) / interval;

      sliderTrack.style.background = `linear-gradient(to right,#eff0f0  ${min}% ,#282830  ${min}% ,#282830  ${max}%, #eff0f0 ${max}%)`;
    },
    updateRangeSlide(value) {
      const sliderMin = document.querySelector(`#${this.labelMin}_sliderMin`);
      const sliderMax = document.querySelector(`#${this.labelMax}_sliderMax`);
      const inputMin = document.querySelector(`#${this.labelMin}_inputMin`);
      const inputMax = document.querySelector(`#${this.labelMax}_inputMax`);
      if (
        parseInt(sliderMax.value) - parseInt(sliderMin.value) <=
        this.minGap
      ) {
        if (value === "min") {
          sliderMin.value = parseInt(sliderMax.value) - this.minGap;
        } else if (value === "max") {
          sliderMax.value = parseInt(sliderMin.value) + this.minGap;
        }
      }
      inputMin.children[0].value = sliderMin.value;
      inputMax.children[0].value = sliderMax.value;
      this.fillColor();
    },
    updateRangeInput(range) {
      const sliderMin = document.querySelector(`#${this.labelMin}_sliderMin`);
      const sliderMax = document.querySelector(`#${this.labelMax}_sliderMax`);
      const inputMin = document.querySelector(`#${this.labelMin}_inputMin`);
      const inputMax = document.querySelector(`#${this.labelMax}_inputMax`);
      if (range === "min") {
        if (!Number.isNaN(parseInt(inputMin.value)) || inputMin.value === "") {
          inputMin.value = parseInt(sliderMin.min);
        }
        if (parseInt(inputMin.value) < parseInt(sliderMin.min)) {
          inputMin.value = parseInt(sliderMin.min);
        } else if (parseInt(inputMin.value) > parseInt(sliderMin.max)) {
          inputMin.value = parseInt(sliderMin.max);
        }
        if (parseInt(inputMin.value) >= parseInt(sliderMax.value)) {
          inputMin.value = parseInt(sliderMax.value) - parseInt(this.minGap);
        }
      } else if (range === "max") {
        if (!Number.isNaN(parseInt(inputMax.value)) || inputMax.value === "") {
          inputMax.value = parseInt(sliderMax.max);
        }
        if (parseInt(inputMax.value) > parseInt(sliderMax.max)) {
          inputMax.value = parseInt(sliderMax.max);
        } else if (parseInt(inputMax.value) < parseInt(sliderMax.min)) {
          inputMax.value = parseInt(sliderMax.min);
        }
        if (parseInt(inputMax.value) <= parseInt(sliderMin.value)) {
          inputMax.value = parseInt(sliderMin.value) + parseInt(this.minGap);
        }
      }
      sliderMin.value = parseInt(inputMin.children[0].value);
      sliderMax.value = parseInt(inputMax.children[0].value);
      this.sendValue();
      this.fillColor();
    },
    sendValue() {
      const sliderMin = document.querySelector(`#${this.labelMin}_sliderMin`);
      const sliderMax = document.querySelector(`#${this.labelMax}_sliderMax`);
      this.$emit("onUpdateValue", {
        valueMin: sliderMin.value,
        valueMax: sliderMax.value,
        rangeMin: this.labelMin,
        rangeMax: this.labelMax,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Inputs";
@import "../FilterUsedCars/FilterUsedCars";
</style>
