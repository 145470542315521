var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{key:_vm.counter,staticClass:"active-filters row"},_vm._l((_vm.filters),function(filter){return _c('div',{key:filter.id,class:filter.type == 'price' ||
        filter.type == 'year' ||
        filter.type == 'kms' ||
        filter.type == 'fee'
          ? 'single-active-filter grid-two-col'
          : 'single-active-filter'},[(
          filter.type == 'price' ||
          filter.type == 'year' ||
          filter.type == 'kms' ||
          filter.type == 'fee'
        )?_c('div',{staticClass:"filter-item center"},[(filter.type == 'price' || filter.type == 'fee')?_c('div',{staticClass:"button-label"},[(filter.min)?_c('span',[_vm._v(_vm._s(_vm.formatPriceNumber(filter.min, "0", "€")))]):_vm._e(),_c('span',{staticStyle:{"margin":"5px"}},[_vm._v("-")]),(filter.max)?_c('span',[_vm._v(_vm._s(_vm.formatPriceNumber(filter.max, "0", "€")))]):_vm._e()]):_c('div',{staticClass:"button-label"},[(filter.min)?_c('span',[_vm._v(_vm._s(filter.min))]):_vm._e(),_c('span',{staticStyle:{"margin-left":"5px","margin-right":"5px"}},[_vm._v("-")]),(filter.max)?_c('span',[_vm._v(_vm._s(filter.max))]):_vm._e()]),_c('span',{staticClass:"remove-icon",on:{"click":function($event){return _vm.onClick(filter)}}},[_c('xIcon')],1)]):_c('div',{staticClass:"filter-item"},[_c('div',{staticClass:"button-label"},[_c('span',[_vm._v(" "+_vm._s(filter.value.replace("%20", " "))+" "),(filter.count > 1)?_c('span',[_vm._v("(+"+_vm._s(filter.count)+")")]):_vm._e()])]),_c('span',{staticClass:"remove-icon",on:{"click":function($event){return _vm.onClick(filter)}}},[_c('xIcon')],1)])])}),0),(_vm.filters.length > 1)?_c('div',{staticClass:"clear-filters",on:{"click":_vm.removeAllFilter}},[_c('p',[_vm._v("Limpar Filtros")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }