<template>
  <div class="filter-single">
    <div
      :class="filterState + 'filter-tab row pointer'"
      @click="toggleFilterSingle"
    >
      <p>{{ label }}</p>
      <Arrow />
    </div>
    <div class="filter-options">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Arrow from "@/assets//Arrow.vue";

export default {
  name: "FilterSingle",
  props: {
    label: { type: String },
  },
  components: {
    Arrow,
  },
  data() {
    return {
      filterState: "close ",
    };
  },
  methods: {
    toggleFilterSingle() {
      this.filterState = this.filterState == "" ? "close " : "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/FilterUsedCars";
</style>
