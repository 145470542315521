<template>
  <div class="checkbox-container row">
    <input
      class="checkbox"
      type="checkbox"
      :id="id"
      :value="value"
      :name="name"
      @click="onChange"
      :data-type="type"
      :checked="checked"
    />
    <label class="checkbox-label" :for="id"
      >{{ value }} <span>({{ length }})</span></label
    >
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    name: {
      type: [Number, String],
      default: 0,
    },
    length: {
      type: [Number, String],
      default: 0,
    },
    type: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // FUNCTION CALLED WHEN CHECKBOX IS CLICKEC (TO CHECKED OR UNCHECKED)
    onChange(event) {
      this.$emit("onUpdateValue", {
        value: event.target.value,
        checked: event.target.checked,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Checkbox";
</style>
