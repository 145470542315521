<template>
  <div class="filter row">
    <FilterSingle label="Disponibilidade" v-if="filters.availability && isNew">
      <div v-for="(availability, index) in filters.availability" :key="index">
        <Checkbox
          v-if="availability.count > 0"
          :id="availability.type + availability.id"
          :value="availability.value"
          :length="availability.count"
          :name="availability.value"
          type="availability"
          @onUpdateValue="checkboxFilter($event, availability)"
        />
      </div>
    </FilterSingle>
    <FilterSingle label="Modelo">
      <div v-for="(model, index) in filters.model" :key="index">
        <Checkbox
          :id="model.type + model.id"
          :value="model.value"
          :length="model.count"
          :name="model.value"
          type="model"
          @onUpdateValue="checkboxFilter($event, model)"
        />
      </div>
    </FilterSingle>
    <FilterSingle label="Versão">
      <div v-for="(version, index) in filters.idvc" :key="index">
        <Checkbox
          v-if="version.count > 0 && version.id"
          :id="version.type + version.id"
          :value="version.value"
          :length="version.count"
          :name="version.value"
          type="version"
          @onUpdateValue="checkboxFilter($event, version)"
        />
      </div>
    </FilterSingle>
    <FilterSingle label="Preço">
      <InputRange
        :min="filters.finance.priceInf"
        :max="filters.finance.priceSup"
        :minValue.sync="labelValues.finance.priceInf"
        :maxValue.sync="labelValues.finance.priceSup"
        labelMax="priceSup"
        labelMin="priceInf"
        ref="inputPrice"
        @onUpdateValue="rangeFilter($event, 'price')"
        @onUpdateLabel="updateLabel($event, 'price')"
      />
    </FilterSingle>
    <FilterSingle label="Cor" v-if="filters.color">
      <div v-for="(color, index) in filters.color" :key="index">
        <Checkbox
          v-if="color.count > 0"
          :id="color.type + color.id"
          :value="color.value"
          :length="color.count"
          :name="color.value"
          type="color"
          @onUpdateValue="checkboxFilter($event, color)"
        />
      </div>
    </FilterSingle>
    <FilterSingle label="Renda" v-if="filters.finance.monthlyPaymentSup">
      <!--       <div class="filter-range row">
        <InputNumber
          :value.sync="labelValues.finance.monthlyMin"
          propType="fee"
          @onUpdateValue="
            rangeFilter({ value: $event, range: 'montlyPayment' }, 'fee')
          "
        />
        <span>-</span>
        <InputNumber
          :value.sync="labelValues.finance.monthlyMax"
          propType="fee"
          @onUpdateValue="
            rangeFilter({ value: $event, range: 'montlyPayment' }, 'fee')
          "
        />
      </div> -->
      <InputRange
        :min="filters.finance.monthlyPaymentInf"
        :max="filters.finance.monthlyPaymentSup"
        :minValue.sync="labelValues.finance.monthlyPaymentInf"
        :maxValue.sync="labelValues.finance.monthlyPaymentSup"
        labelMax="monthlyPaymentSup"
        labelMin="monthlyPaymentInf"
        ref="inputFee"
        :step="[filters.finance.monthlyStep]"
        @onUpdateValue="rangeFilter($event, 'fee')"
        @onUpdateLabel="updateLabel($event, 'fee')"
      />
    </FilterSingle>
    <FilterSingle label="Ano" v-if="filters.year && !isNew">
      <!--    <div class="filter-range row">
        <InputNumber
          :value.sync="labelValues.year.min"
          propType="year"
          @onUpdateValue="
            rangeFilter({ value: $event, range: 'yearInf' }, 'year')
          "
        />
        <span>-</span>
        <InputNumber
          :value.sync="labelValues.year.max"
          propType="year"
          @onUpdateValue="
            rangeFilter({ value: $event, range: 'yearSup' }, 'year')
          "
        />
      </div> -->
      <InputRange
        :min="filters.year.yearInf"
        :max="filters.year.yearSup"
        :minValue.sync="labelValues.year.yearInf"
        :maxValue.sync="labelValues.year.yearSup"
        :step="[filters.year.yearInf, filters.year.yearSup]"
        labelMax="yearSup"
        labelMin="yearMin"
        ref="inputYear"
        @onUpdateValue="rangeFilter($event, 'year')"
        @onUpdateLabel="updateLabel($event, 'year')"
      />
    </FilterSingle>
    <FilterSingle label="Quilómetros" v-if="filters.km && !isNew">
      <!--   <div class="filter-range row">
        <InputNumber
          :value.sync="labelValues.km.min"
          propType="kms"
          @onUpdateValue="rangeFilter({ value: $event, range: 'kmInf' }, 'kms')"
        />
        <span>-</span>
        <InputNumber
          :value.sync="labelValues.km.max"
          propType="kms"rangeFilterrange
          @onUpdateValue="rangeFilter({ value: $event, range: 'kmSup' }, 'kms')"
        />
      </div> -->
      <InputRange
        :min="filters.km.kmInf"
        :max="filters.km.kmSup"
        :minValue.sync="labelValues.km.kmInf"
        :maxValue.sync="labelValues.km.kmSup"
        :step="[filters.km.kmInf, filters.km.kmSup]"
        labelMax="kmSup"
        labelMin="kmInf"
        ref="inputKm"
        @onUpdateValue="rangeFilter($event, 'kms')"
        @onUpdateLabel="updateLabel($event, 'kms')"
      />
    </FilterSingle>
    <FilterSingle label="Combustível" v-if="filters.fuel">
      <div v-for="(fuel, index) in filters.fuel" :key="index">
        <Checkbox
          v-if="fuel.count > 0"
          :id="fuel.type + fuel.id"
          :value="fuel.value"
          :length="fuel.count"
          :name="fuel.value"
          type="fuelType"
          @onUpdateValue="checkboxFilter($event, fuel)"
        />
      </div>
    </FilterSingle>
    <FilterSingle label="Instalação" v-if="filters.dealer && !isNew">
      <div v-for="(dealer, index) in filters.dealer" :key="index">
        <Checkbox
          v-if="dealer.count > 0"
          :id="dealer.type + dealer.id"
          :value="dealer.value"
          :length="dealer.count"
          :name="dealer.value"
          :checked="formatToSlug(dealerSelected) == formatToSlug(dealer.value)"
          type="location"
          @onUpdateValue="checkboxFilter($event, dealer)"
        />
      </div>
    </FilterSingle>
  </div>
</template>

<script>
import Checkbox from "@/components/Checkbox/Checkbox.vue";
import FilterSingle from "@/components/FilterUsedCars/FilterSingle.vue";
//import InputNumber from "@/components/Inputs/InputNumber.vue";
import InputRange from "@/components/Inputs/InputRange.vue";

//import * as filter from "@/functions/filter";
import { formatToSlug } from "@/functions/general";

export default {
  name: "FilterOptions",
  components: {
    Checkbox,
    FilterSingle,
    //InputNumber,
    InputRange,
  },
  props: {
    filters: { type: Object },
  },
  data() {
    return {
      dealerSelected: this.$route.params.dealer,
      checked: false,
      labelValues: {},
    };
  },
  created() {
    this.labelValues = this.filters;
  },
  watch: {
    /*  "$store.state.changeCounter": function () {
      this.$forceUpdate();
    }, */
    $route() {
      if (this.$route.hash.length > 0) {
        let modelId = this.$route.hash
          .substr(1, this.$route.hash.length - 1)
          .split("+")[0];
        let model = this.$route.hash
          .substr(1, this.$route.hash.length - 1)
          .split("+")[1];
        model = model.replace("%20", " ");
        let event = {
          checked: true,
          value: model,
        };
        let type = {
          id: modelId,
          type: "model",
          value: model,
        };
        this.$emit("checkboxFilterReplace", { value: event, type: type });
      }
    },
  },
  computed: {
    isNew() {
      return this.$route.name == "Novos";
    },
  },
  methods: {
    formatToSlug,
    updateLabel: function (value, type) {
      let val;
      if (type == "price") {
        if (value.range == "priceInf") {
          val = "priceMin";
        }
        if (value.range == "priceSup") {
          val = "priceMax";
        }
        this.$set(this.labelValues["finance"], val, value.value);
      }
    },
    checkboxFilter(event, type) {
      this.$emit("checkboxFilter", { value: event, type: type });
    },
    rangeFilter(event, type) {
      this.$emit("rangeFilter", { event, type });
    },
    update(name) {
      document.querySelectorAll('input[type="checkbox"]').forEach((e) => {
        if (e.getAttribute("data-type") == "model" && e.value == name) {
          e.checked ^= 1;
        }
      });
    },
    resetRange(type = null) {
      if (type == null) {
        this.$refs.inputPrice.fillColor();
        this.$refs.inputFee.fillColor();
        if (this.$refs.inputKm) {
          this.$refs.inputKm.fillColor();
        }
        if (this.$refs.inputYear) {
          this.$refs.inputYear.fillColor();
        }
      } else if (type == "price") {
        this.$refs.inputPrice.fillColor();
      } else if (type == "fee") {
        this.$refs.inputFee.fillColor();
      } else if (type == "kms") {
        this.$refs.inputKm.fillColor();
      } else if (type == "year") {
        this.$refs.inputYear.fillColor();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/FilterUsedCars";
</style>
