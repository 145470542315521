<template>
  <div class="rel">
    <input
      class="input-filter"
      type="text"
      @change="onChange"
      @keyup="formatNumber"
      :value="value"
    />
    <span
      v-if="
        type == 'priceSup' ||
        type == 'monthlyPaymentSup' ||
        type == 'priceInf' ||
        type == 'monthlyPaymentInf'
      "
      class="sign"
      >€
    </span>
    <span v-if="type == 'kmSup' || type == 'kmInf'" class="sign">KM </span>
  </div>
</template>

<script>
export default {
  name: "InputNumber",
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    propType: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  methods: {
    // FUNCTION CALLED AFTER VALUE IS CHANGED ON THE INPUT TEXT - UPDATES VALUE AND CLEARS THE TEXT
    onChange(event) {
      if (
        this.type == "priceSup" ||
        this.type == "monthlyPaymentSup" ||
        this.type == "kmSup" ||
        this.type == "yearSup"
      ) {
        if (event.target.value > this.value) {
          event.target.value = Math.round(this.value);
        }
      } else if (
        this.type == "priceInf" ||
        this.type == "monthlyPaymentInf" ||
        this.type == "kmInf" ||
        this.type == "yearMin"
      ) {
        if (event.target.value < this.value) {
          event.target.value = Math.round(this.value);
        }
      }
      this.$emit("inputChange");
    },
    // FUNCTION CALLED ON KEY UP TO FORMAT THE NUMBER ON THE FLY
    formatNumber(event) {
      event.target.value = Math.round(event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Inputs";
.rel {
  position: relative;
}
.sign {
  position: absolute;
  margin: 0;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: $mainFontSize;
  color: $primaryColor;
  font-weight: 400;
}
</style>
